import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

// General pages
const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Admin = lazy(() => import('./pages/Admin'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Support = lazy(() => import('./pages/Support'));

// Team pages
const TeamHome = lazy(() => import('./pages/Team/Team_Home'));
const AdminHome = lazy(() => import('./pages/Team/Admin/Admin'));
const Manage = lazy(() => import('./pages/Team/Admin/Manage'));
const RolesManagement = lazy(() => import('./pages/Team/Admin/Roles'));
const Staff = lazy(() => import('./pages/Team/Staff'));
const ViewStaff = lazy(() => import('./pages/Team/ViewStaff'));

// Registering pages
const RegisterTeam = lazy(() => import('./pages/register/register-team'));
const PlanTeam = lazy(() => import('./pages/register/plan'));
const PaymentTeam = lazy(() => import('./pages/register/payment'));
const SetupRolesTeam = lazy(() => import('./pages/register/setup-roles'));

// Financial pages
const CheckoutForm = lazy(() => import('./pages/Financial/CheckoutForm'));
const PaymentSuccessfull = lazy(() => import('./pages/Financial/payment-success'));
const PaymentCancelled = lazy(() => import('./pages/Financial/payment-cancelled'));

function App() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/team/:teamName" element={<TeamHome />} />
                    <Route path="/team/:teamName/home" element={<TeamHome />} />
                    <Route path="/team/:teamName/admin" element={<AdminHome />} />
                    <Route path="/team/:teamName/manage" element={<Manage />} />
                    <Route path="/team/:teamName/admin/roles" element={<RolesManagement />} />
                    <Route path="/team/:teamName/staff" element={<Staff />} />
                    <Route path="/team/:teamName/staff/:userName" element={<ViewStaff />} />

                    {/* Register Team Routes */}
                    <Route path="/register-team" element={<RegisterTeam />} />
                    <Route path="/register-team/plan" element={<PlanTeam />} />
                    <Route path="/register-team/payment" element={<PaymentTeam />} />
                    <Route path="/register-team/setup-roles" element={<SetupRolesTeam />} />

                    {/* Financial Routes with Elements wrapper for Stripe */}
                    <Route
                        path="/register-team/checkout"
                        element={<CheckoutForm />}
                    />
                    <Route path="/register-team/payment-successful" element={<PaymentSuccessfull />} />
                    <Route path="/register-team/payment-cancelled" element={<PaymentCancelled />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default App;
